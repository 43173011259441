import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

import { isInRange } from 'later/utils/number-helpers';

/**
 *
 *  Validates the video duration of a PostMediaItem
 *
 *  @class VideoDuration
 *  @module Validators
 *  @extends Base
 */
@classic
class VideoDuration extends BaseValidator {
  @service errors;
  @service segment;

  DURATION = {
    InstagramReel: { min: 3000, max: 900000 },
    InstagramReelAuto: { min: 3000, max: 180000 },
    Instagram: { min: 0, max: 3600000 },
    InstagramAuto: { min: 3000, max: 60000 },
    Twitter: { min: 500, max: 140000 },
    Facebook: { min: 1000, max: 2400000 },
    FacebookReel: { min: 4000, max: 60000 },
    Pinterest: { min: 4000, max: 900000 },
    Tiktok: { min: 3000, max: 180000 },
    TiktokAuto: { min: 3000, max: 60000 },
    Linkedin: { min: 3000, max: 600000 },
    Youtube: { min: 0, max: 60000 },
    Threads: { min: 0, max: 300000 },
    Snapchat: { min: 5000, max: 60000 }
  };

  validate(value, _, model) {
    const duration = model.videoDuration * 1000;

    if (duration && model.get('gram.socialProfile.profileType')) {
      let profileType = capitalize(model.get('gram.socialProfile.profileType'));
      const canAutoPublish = (model.isInstagram || model.isTiktok) && model.isAutoPublish;
      const autoPublishSuffix = canAutoPublish ? 'Auto' : '';
      profileType += model.isReel ? 'Reel' : '';
      const durationKey = `${profileType}${autoPublishSuffix}`;

      if (!this.DURATION[durationKey]) {
        this.errors.log(`No duration data available for ${durationKey}`);
        this.createErrorMessage('generic');
        return true;
      }

      const { min } = this.DURATION[durationKey];
      const { max } = this.DURATION[durationKey];

      if (canAutoPublish) {
        if (duration !== max && isInRange(duration, max, max + 1000)) {
          // Automatically trim end of videos which are under 1 second longer than allowed
          // https://latergramme.atlassian.net/browse/LF-736
          next(() => {
            model.set('endTime', max / 1000);
          });
        } else if (!isInRange(duration, min, max)) {
          this.segment.track('scheduled-video-incorrect-trimmed-video', {
            social_profile_id: model.get('gram.socialProfile.id'),
            length: duration.toString()
          });
          return this.#createError({ duration, durationKey, min, max, canAutoPublish });
        }
      } else if (this.DURATION[durationKey] && !isInRange(duration, durationKey, min, max)) {
        return this.#createError({ duration, durationKey, min, max, canAutoPublish });
      }
    }
    return true;
  }

  #createError({ duration, durationKey, min, max }) {
    if (duration < min) {
      return this.createErrorMessage(`invalidVideoDuration${durationKey}`, duration, { error: 'short' });
    } else if (duration > max) {
      return this.createErrorMessage(`invalidVideoDuration${durationKey}`, duration, { error: 'long' });
    }
    return true;
  }
}

export default VideoDuration;
