import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { NetworkError } from 'later/errors/fetch';
import { fetch } from 'later/utils/fetch';
import { isEnv } from 'later/utils/is-env';

interface ConfigResponse {
  availability: ResourceAvailabilty;
  later_config: ResourceConfig;
}

// These interfaces should be updated once the BE documents the endpoint.
// https://latergramme.atlassian.net/browse/IENG-373
// https://latergramme.atlassian.net/browse/IENG-374
interface ResourceConfig {
  authenticity_token: string;
  aws_identity_pool?: string; // Unused
  aws_s3_upload_bucket?: string; // Unused
  camera_roll_fallback?: boolean; // Unused
  facebook_app_id: string;
  faye_host: string;
  google_api_key: string;
  google_client_id: string;
  ig_proxy: string;
  instagram_client_id: string;
  keen_project_id?: string; // Unused
  linkedin_api_version: string;
  pinterest_proxy_host?: string; // Unused
  pinterest_proxy: string;
  shortlink_prefix?: string; // Unused
  smarty_proxy?: string; // Unused
  stripe_publishable_key: string;
  submit_media_email_domain: string;
  keycloak_sso_enabled: boolean;
  keycloak_auth_url: string;
  delay_keycloak_login: boolean;
}

interface ResourceAvailabilty {
  dropbox: boolean;
  image_uploading: boolean;
  analytics?: boolean; // Unused
  apns_sns?: boolean; // Unused
  google_drive?: boolean; // Unused
  stripe?: boolean; // Unused
}

/**
 * This service provides the following dependency information required by the `ember-later` application.
 * 1) Resource information - are resources such as `dropbox` available?
 * 2) Configuration information - Proxy URLs, application IDs etc
 */
export default class LaterConfigService extends Service {
  /**
   * Stores the status/availability of resources
   */
  @tracked
  private _resourceAvailability: ResourceAvailabilty = {
    dropbox: false,
    image_uploading: false
  };

  /**
   * Stores config data for Later resources
   */
  @tracked
  private _resourceConfig: ResourceConfig = {
    authenticity_token: '',
    facebook_app_id: '',
    faye_host: '',
    google_api_key: '',
    google_client_id: '',
    ig_proxy: '',
    instagram_client_id: '',
    linkedin_api_version: '',
    pinterest_proxy: '',
    stripe_publishable_key: '',
    submit_media_email_domain: '',
    keycloak_sso_enabled: false,
    keycloak_auth_url: '',
    delay_keycloak_login: false
  };

  get dropboxAvailable(): boolean {
    return this._resourceAvailability.dropbox;
  }

  get imageUploadAvailable(): boolean {
    return this._resourceAvailability.image_uploading;
  }

  /**
   * Nexus URL for the Faye access (websocket server)
   */
  get fayeHost(): string {
    return this._resourceConfig.faye_host;
  }

  /**
   * Jolteon URL for Instagram proxying
   */
  get igProxy(): string {
    return this._resourceConfig.ig_proxy;
  }

  /**
   * Nexus URL for Pinterest proxying
   */
  get pinterestProxy(): string {
    return this._resourceConfig.pinterest_proxy;
  }

  get facebookAppId(): string {
    return this._resourceConfig.facebook_app_id;
  }

  get stripePublishableKey(): string {
    return this._resourceConfig.stripe_publishable_key;
  }

  /**
   * Google Drive API client ID
   */
  get googleClientID(): string {
    return this._resourceConfig.google_client_id;
  }

  /**
   * Google Drive API key
   */
  get googleApiKey(): string {
    return this._resourceConfig.google_api_key;
  }

  get instagramClientId(): string {
    return this._resourceConfig.instagram_client_id;
  }

  get linkedinApiVersion(): string {
    return this._resourceConfig.linkedin_api_version;
  }

  /**
   * Email domain for which a contributor should submit media
   */
  get submitMediaEmailDomain(): string {
    return this._resourceConfig.submit_media_email_domain;
  }

  /**
   * Authenticity token to help with logins
   */
  get authenticityToken(): string {
    return this._resourceConfig.authenticity_token;
  }

  /**
   * Indicates if the Keycloak SSO is enabled
   */
  get keycloakSSOEnabled(): boolean {
    const isPrAppEnv = window.location.hostname.includes('amplifyapp.com');
    const localBackendEnv = window.location.hostname.includes('localhost') && window.location.port === '3000';
    const ngrokEnv = window.location.hostname.includes('ngrok.io');
    if (isEnv('development') || isPrAppEnv || localBackendEnv || ngrokEnv) {
      return false;
    }
    return this._resourceConfig.keycloak_sso_enabled;
  }

  get keycloakAuthUrl(): string {
    return this._resourceConfig.keycloak_auth_url;
  }

  get delayKeycloakLogin(): boolean {
    return this._resourceConfig.delay_keycloak_login;
  }

  /**
   * Populates ConfigHash and ResourceHash.
   */
  setup(): Promise<void> {
    return this.#fetchAvailabilityData();
  }

  #fetchAvailabilityData(): Promise<void> {
    return fetch('/api/v2/config.json')
      .then((response: ConfigResponse) => {
        this._resourceAvailability = response.availability;
        this._resourceConfig = response.later_config;
      })
      .catch((error: any) => {
        if (error instanceof NetworkError) {
          error.resolve();
        }
      });
  }
}

declare module '@ember/service' {
  interface Registry {
    'later-config': LaterConfigService;
  }
}
